.projects-section{
    flex-direction: column;
}

.projects-list{
    display: flex;
    align-items: center;
    gap: 5rem;
    padding-top: 3rem;


    .project-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        flex: 1;
        height: 100%;
        border: 1px solid #7a7a7a;
        transform-origin: top;
        position: relative;
        overflow: hidden;

        .project-background{
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
               object-fit: cover;
            opacity: .5;
            position: absolute;
            z-index: -1;
        }
        .project-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3rem;
            padding: 3rem 0;

            h2{
                font-family: "Adamina", serif;
                letter-spacing: .2rem;
            }

            .project-buttons{
                display: flex;
                align-items: center;
                gap: 1rem;
                a{
                    color: white;
                }
                svg{
                    height: 35px;
                    padding: .25rem;
                }
                .buttons-divider{
                    height: 10px;
                }
            }

        }
    }
}

@media screen and (max-width:1200px) {
    .projects-list{
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: stretch;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;

        .project-item{
            height: 100%;
            .project-content{
                gap: 1rem;
                h2{
                    text-align: center;
                }
            }
        }
    }
}