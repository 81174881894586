
.contact-heading{
    line-height: 3rem;
    font-family: 'Adamina', serif;
    h3{
        display: flex;
        align-items: center;
        gap: .2rem;
        font-size: .75rem;
        svg{
            height: 20px;
        }
    }
}

form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    margin-top: 1rem;

    .field-container{
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;
        label{
            font-family: "Adamina", serif;
            font-weight: bold;
        }
        input, textarea{
            flex: 0 1 auto;
            -webkit-appearance: none;
               -moz-appearance: none;
                    appearance: none;
            outline: none;
            border: none;
            padding: .75rem;
            background: none;
            border: 2px solid #585858;
            color: white;
            border-radius: .5rem;
            margin-top: .5rem;
            font-family: "Montserrat", sans-serif;
        }:focus{
            border-color: rgba(255, 102, 0,0.4);
        }
    }

    .form-send{
        outline: none;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background: none;
        border: none;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background-color: #ff9b58;
        color: white;
        padding: 1rem 3rem;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
        border-radius: 10px;
        transition: all .2s ease-in-out;
        .loading-animation{
            display: flex;
            svg{
                height: 20px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    form{
        width: 100%;
    }
}