@import url('https://fonts.googleapis.com/css2?family=Adamina&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

section{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 2px rgb(39, 39, 39) solid;
    .content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            font-family: 'Montserrat', sans-serif;
            color: white;
            width: 100%;
            height: 100%;

            .section-heading{
                letter-spacing: .5rem;
                font-size: 5rem;
                color: white;
                font-family: 'Adamina', serif;
            }

            .section-text{
                font-size: 1.5rem;
            }

        }
        .image-collection-mb{
            display: none;
        }
        .image-collection-desktop{
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            position: relative;
            width: 100%;
                img{
                    width: 40%;
                    border: 10px solid white;
                }
                .image-behind{
                    position: absolute;
                    top: 0;
                    left: 50;
                    z-index: -1;
                }
        }
    }

    @media screen and (max-width:2300px) {
        .about-section{
            flex-direction: column;
            .image-collection-desktop{
                width: 50%;
            }
        }
    }

    @media screen and (max-width:1980px) {
        .about-section{
            .image-collection-desktop{
                width: 75%;
            }
        }
    }

    @media screen and (max-width:1750px){
        .about-section{
            .content{
                .section-text{
                    font-size: 1rem;
                }
            }
        }
    }

    @media screen and (max-width: 1300px) {
        section{
            flex-direction: column;
            padding: 5rem 0;
            .content{
                .section-heading{
                    font-size: 3rem;
                }
                .section-text{
                    font-size: 1rem;
                }
            }
            .image-collection-desktop{
                display: none;
            }
            .image-collection-mb{
                display: block;
                width: 70%;
                img{
                    width: 50%;
                    border: 5px solid white;
                }
            }
        }
    }

    @media screen and (max-width:1000px) {
        section{
            .image-collection-mb{
                width: 100%;
            }
        }
    }
