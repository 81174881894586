@import '../../Variables.scss';

section{
    .content{
        ul{
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 5rem;
            li{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                svg{
                    padding: 1rem;
                    border-radius: 5px;
                    border: 1px white solid;
                }

                .skill-content{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width:800px) {
    section{
        .content{
            ul{
                padding-top: 3rem;
                gap: 1rem;
                justify-content: space-between;
                li{
                    flex: 1;
                    .skill-content{
                        justify-content: start;
                        width: 100%;
                        h2{
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}