.experience-list{
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
    padding-top: 3rem;

    .experience-item{
        border-radius: 100px;
        border: 1px solid white;
        padding: 1rem 3rem;
        width: 40%;
        transition: color .2s ease-in-out, background-color .1s ease-in-out;
        .experience-content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
            img{
                border-radius: 100%;
                height: 100px;
            }
            .experience-text{
                .job-title{
                    font-family:"Adamina", serif;
                    letter-spacing: 0.1rem;
                }
                .company-text{
                    font-weight: 400;
                    font-size: 1rem;
                }
                .experience-date-mb{
                    display: none;
                }
            }

            .experience-date{
                h2{
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }
    }

    .experience-bulletpoints{
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .bulletpoint{
            display: flex;
            gap: 1rem;
            align-items: flex-start;
        }
    }
}

@media screen and (max-width:1200px) {
    .experience-list{
        flex-direction: column;
        width: 100%;
        .experience-item{
            width: 100%;
            padding: 1rem;
            .experience-content{
                img{
                    height: 75px;
                }
                .experience-text{
                    .experience-date-mb{
                        display: block;
                        font-weight: normal;
                        font-size: 1rem;
                    }
                    .job-title{
                        font-size: 1.2rem;
                    }
                }
                .experience-date{
                    h2{
                        display: none;
                    }
                }
            }
        }
    }
}