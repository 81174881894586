@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;700&family=Staatliches&display=swap");
section .content ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
}
section .content ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section .content ul li svg {
  padding: 1rem;
  border-radius: 5px;
  border: 1px white solid;
}
section .content ul li .skill-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media screen and (max-width: 800px) {
  section .content ul {
    padding-top: 3rem;
    gap: 1rem;
    justify-content: space-between;
  }
  section .content ul li {
    flex: 1;
  }
  section .content ul li .skill-content {
    justify-content: start;
    width: 100%;
  }
  section .content ul li .skill-content h2 {
    font-size: 1.2rem;
  }
}/*# sourceMappingURL=Skills.css.map */