@import url('https://fonts.googleapis.com/css2?family=Adamina&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
html{
    scroll-behavior: smooth;
}

.no-scroll{
    overflow: hidden;
}

body::-webkit-scrollbar{
    background-color: transparent;
}

body::-webkit-scrollbar-thumb{
    background-color: rgb(100, 100, 100);
    border-radius: 5px;
}

body::-webkit-scrollbar-track{
    padding: 0 1rem;
}

.hero-wrapper{
    max-height: 100dvh;
    position: relative;
    overflow: hidden;

    .overlay{
        position: absolute;
        top: 0;
        right: 0;
        height: 100dvh;
        width: 100%;
        background: radial-gradient(50% bottom, transparent, black);
        background: -webkit-radial-gradient(100%,transparent, rgb(27, 27, 27) 65%);
        z-index: 1
    }

    .hamburger, .hamburger-menu{
        display: none;
    }

    .back-to-top{
        display: block;
        position: fixed;
        z-index: 6;
        bottom: 10px;
        right: 10px;
        a{
            color: white;
        }
        svg{
            height: 50px;
        }
    }

    video{
        max-height: 100vh;
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        z-index: 5;
    }

    .hero-content{
        position: absolute;
        top:40px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        width: 100%;
        height: 100vh;
        z-index: 4;

        ul{
            display: flex;
            align-items: center;
            justify-content: space-around;
            li{
                list-style: none;
                font-size: 2rem;
                cursor: pointer;
                position: relative;
                transition: all .2s ease-in;
                padding: .5rem 2rem;
                color: rgb(255, 255, 255);
                z-index: 1;
                a{
                    color: white;
                    text-decoration: none;
                }
            }::after{
                content: '';
                height: 100%;
                width: 0px;
                background-color: #bdbdbd;
                position: absolute;
                top: 0;
                left: 0;
                transition: all .2s ease;
                opacity: 0.3;
                z-index: -1;
            }:hover::after{
                width: 100%;
            }
            .contact-button{
                background-color: #ff9b58;
            }:nth-child(6)::after{
                background-color: red;
            }
        }
    }

    .text-content{
        font-family: 'Adamina', serif;
        font-weight: normal;
        color: white;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        h1{
            font-size: 12rem;
            letter-spacing: 2rem;
        }

        h2{
            font-size: 2rem;
            letter-spacing: .5rem;
            color: rgb(255, 255, 255);
            padding: .4rem 2rem;
        }
        .certifications{
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 2rem;
            .cert-link{
                color: white;
                text-decoration: none;
            }

            svg{
                height: 10px;
            }
            .cert-item{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .5rem;
                svg{
                    height: 40px;
                    opacity: 0.8;
                }
                h3{
                    font-size: 1rem;
                    font-weight: normal;
                }
            }

        }
        .scroll{
            position: absolute;
            bottom: 50px;
            right: 45%;
            color:white;
            width: 200px;
        }
    }
}

@media screen and (max-width: 1920px) {
    .desktop-nav{
        display: none;
    }

    .hero-wrapper{
        .hamburger{
            display: block;
            position: fixed;
            top: 10px;
            left: 10px;
            color: rgb(245, 245, 245);
            z-index: 6;
            svg{
                height: 40px;
            }
        }

        .hamburger-menu{
            display: block;
            background-color: rgb(54, 54, 54);
            height: 100dvh;
            width: 100%;
            position: fixed;
            top: 0;
            z-index: 5;
            .nav-mobile{
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 5rem 1rem;
                h2{
                    color: white;
                }
                ul{
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    list-style: none;
                    width: 100%;
                    margin-top: 1rem;
                    li{
                        font-size: 2rem;
                        padding: 0.5rem 0;
                        background-color: #424242;
                        border: 2px rgb(78, 78, 78) solid;
                        border-radius: 10px;
                        a{
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            color: white;
                            text-decoration: none;
                            padding-left: 1rem;
                            font-size: 1.5rem;
                            svg{
                                height: 10px;
                            }
                        }
                    }
                }
                .socials{
                    display: flex;
                    flex-direction: column;
                    margin-top: 1rem;
                    padding-top: 1rem;
                    width: 100%;
                    justify-content: space-around;
                    .socials-icons{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 1rem;
                        a{
                            svg{
                                height: 50px;
                                color: rgb(255, 255, 255);
                            }
                        }svg{
                            color: #ff9b58;
                            height: 15px;
                        }
                    }

                }
            }
        }
        .text-content{
            h1{
                text-align: center;
                line-height: 15rem;
                font-size: 17rem;
            }

            .certifications{
                margin-top: 3rem;
                .cert-item{
                    h3{
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1600px) {
    .hero-wrapper{
        min-height: 100dvh;
        .text-content{
            h1{
                font-size: 10rem;
                line-height: 9rem;
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .desktop-nav{
        display: none;
    }
    .hero-wrapper{
        position: relative;
        overflow-x: hidden;
        .hero-content{
            top: 0;
        }
        video{
            min-height: 100dvh;
        }
        .text-content{
            height: 100dvh;
            text-align: center;
            gap: 1rem;
            .certifications{
                margin-top: 1rem;
                .cert-item{
                    flex-direction: column;
                    h3{
                        font-size: 1rem;
                    }
                }
            }
            h2{
                font-size: 2rem;
                letter-spacing: normal;
                padding: 0.2rem 0.5rem;
            }
            h1{
                font-size: 8rem;
                letter-spacing: 0.2rem;
                line-height: 5rem;
            }
            .scroll{
                right: 25%;
            }
        }
    }
}

@media screen and (max-width:1080px) {
    .hero-wrapper{
        .text-content{
            h1{
                font-size: 10rem;
                line-height: 9rem;
            }
        }
    }
}

@media screen and (max-width:700px) {
    .hero-wrapper{
        .text-content{
            h1{
                font-size:6rem;
                line-height: 6rem;
            }
            h2{
                font-size: 1rem;
            }
        }
    }
}

