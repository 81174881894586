.contact-heading {
  line-height: 3rem;
  font-family: "Adamina", serif;
}
.contact-heading h3 {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 0.75rem;
}
.contact-heading h3 svg {
  height: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  margin-top: 1rem;
}
form .field-container {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
}
form .field-container label {
  font-family: "Adamina", serif;
  font-weight: bold;
}
form .field-container input, form .field-container textarea {
  flex: 0 1 auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  padding: 0.75rem;
  background: none;
  border: 2px solid #585858;
  color: white;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  font-family: "Montserrat", sans-serif;
}
form .field-container :focus {
  border-color: rgba(255, 102, 0, 0.4);
}
form .form-send {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ff9b58;
  color: white;
  padding: 1rem 3rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}
form .form-send .loading-animation {
  display: flex;
}
form .form-send .loading-animation svg {
  height: 20px;
}

@media screen and (max-width: 1200px) {
  form {
    width: 100%;
  }
}/*# sourceMappingURL=Contact.css.map */