* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(26, 26, 26);
}

.content-wrapper {
  width: 80%;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .content-wrapper {
    width: 90%;
  }
}/*# sourceMappingURL=App.css.map */