.projects-section {
  flex-direction: column;
}

.projects-list {
  display: flex;
  align-items: center;
  gap: 5rem;
  padding-top: 3rem;
}
.projects-list .project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  height: 100%;
  border: 1px solid #7a7a7a;
  transform-origin: top;
  position: relative;
  overflow: hidden;
}
.projects-list .project-item .project-background {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0.5;
  position: absolute;
  z-index: -1;
}
.projects-list .project-item .project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 3rem 0;
}
.projects-list .project-item .project-content h2 {
  font-family: "Adamina", serif;
  letter-spacing: 0.2rem;
}
.projects-list .project-item .project-content .project-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.projects-list .project-item .project-content .project-buttons a {
  color: white;
}
.projects-list .project-item .project-content .project-buttons svg {
  height: 35px;
  padding: 0.25rem;
}
.projects-list .project-item .project-content .project-buttons .buttons-divider {
  height: 10px;
}

@media screen and (max-width: 1200px) {
  .projects-list {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: stretch;
    height: -moz-fit-content;
    height: fit-content;
  }
  .projects-list .project-item {
    height: 100%;
  }
  .projects-list .project-item .project-content {
    gap: 1rem;
  }
  .projects-list .project-item .project-content h2 {
    text-align: center;
  }
}/*# sourceMappingURL=Projects.css.map */