.diagrams-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
  padding: 3rem 0;
}
.diagrams-list .diagram-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
  position: relative;
  height: 700px;
  width: 80%;
  border: 1px rgb(73, 73, 73) solid;
}
.diagrams-list .diagram-item .background-image {
  width: 100%;
  height: 100%;
  filter: opacity(0.1) blur(1px);
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
}
.diagrams-list .diagram-item h2 {
  font-family: "Adamina", serif;
  font-size: 3rem;
}
.diagrams-list .diagram-item h3 {
  font-family: "Adamina", sans-serif;
  color: #ff9b58;
}
.diagrams-list .diagram-item .diagram-image {
  -o-object-fit: contain;
  object-fit: contain;
  max-height: 600px;
}
.diagrams-list .diagram-item .diagram-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100%;
  width: 45%;
}
.diagrams-list .diagram-item .diagram-text .diagram-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.diagrams-list .diagram-item .diagram-text .diagrams-bullepoints {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.diagrams-list .diagram-item .diagram-text .diagrams-bullepoints .bulletpoint {
  display: flex;
  gap: 1rem;
}
.diagrams-list .diagram-item .diagram-divider {
  height: 30px;
}
.diagrams-list :nth-child(odd) .background-image {
  transform: scaleX(-1);
  border-color: red;
}

@media screen and (max-width: 1200px) {
  .diagrams-list .diagram-item {
    flex-direction: column;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2rem 0;
  }
  .diagrams-list .diagram-item h2 {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  .diagrams-list .diagram-item .diagram-text {
    flex-direction: row;
    flex-direction: column;
    width: 90%;
  }
  .diagrams-list .diagram-item .diagram-divider {
    display: none;
  }
  .diagrams-list .diagram-item .diagram-image {
    max-width: 100%;
  }
}/*# sourceMappingURL=Diagrams.css.map */